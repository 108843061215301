.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}
.dynamic-delete-button:hover {
    color: red;
}
.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
.info-price{
    height: 300px;
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
.modalPrAction{
    width: 600px;
    height: 70%;
    background: #F5F5F5;
    overflow-y: scroll;
    margin-bottom: 25px;
}
.ant-table-row:hover .actions {
    display: block;

}