.diem-di{
    overflow-y: scroll;
    margin-bottom: 25px;
    height: 700px;
}
.boxStyle{
    width: 80%;
    height: 120px;
    borderRadius: 6px;
    border: 1px solid;
}