.custom-modal-ncc {
  width: 85% !important; /* Đảm bảo modal chiếm 80% chiều rộng màn hình */
  font-size: 16px !important;
}
.custom-modal-ncc
  .ant-modal-content
  .ant-modal-body
  .ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .MuiInputBase-root {
  font-size: 14px !important;
}

/* .custom-modal-ncc
  .ant-modal-content
  .ant-modal-body
  .ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  table {
  table-layout: fixed !important;
} */
