table {
    border: 1px solid #f0f0f0;
    width: 100%;
    height: 100%;
}

th {
    border-bottom: 1px solid #f0f0f0;
    border-inline-end: 1px solid #f0f0f0;
    padding: 16px 16px;
    overflow-wrap: break-word;
}

td {
    text-align: left;
    border-inline-end: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    padding: 16px 16px;
    overflow-wrap: break-word;
}