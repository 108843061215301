.topBar {
    display: flex;
    width: 100%;
}
/*.topBar > div {*/
/*    width: 100px;*/
/*    height: 100px;*/

/*}*/
.topBarLeft{
    float: left;
    width: 40%;
}
.topBarRight{
    float: right;
    width: 50%;
}
