.custom-modal {
  width: 75% !important; /* Đảm bảo modal chiếm 80% chiều rộng màn hình */
  font-size: 16px !important;
}
.custom-modal
  .ant-modal-content
  .ant-modal-body
  .ant-table-wrapper
  .ant-table
  .ant-table-container
  .ant-table-content
  .ant-table-tbody
  .MuiInputBase-root {
  font-size: 14px !important;
}
