.login-form {
    display: flex;
    flex-direction: column;
    margin: auto;
    padding: 2vw 1vw;
    background-color: #ffffff;
    border-radius: 0 0 10px 10px;
    border-top: 3px solid #007bff;
    width: 360px;
}

.login-form-button{
    margin-left: 40%;
}
.login-form-forgot {
    float: right;
}
.login-form-forgot {
    float: left;
}
.login {
    background-color: #e9ecef;
    height: 97vh;
}