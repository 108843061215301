.InputAddressDetail{
    margin-left: -18px;
    width: 99%;
}
.SelectAddress{
    margin-left: -10px;
    width: 98%;
}

.UploadPdf{
    margin-left: -20px;
    width: 99%;
    height: 80%;
}